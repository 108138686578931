import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { color } from "src/constants";
import Footer from "templates/sidebar/Footer";
import Icon from "src/components/atoms/Icon";

const style = Object.freeze({
  content: css``,
  container: css``,
  indexes: css`
    padding: 32px;
    letter-spacing: 0.8px;
    color: ${color.fontColor};

    a {
      color: ${color.fontColor};
    }

    a:hover {
      opacity: 0.6;
    }

    h2 {
    }
    h3 {
      font-size: 0.9rem;
    }

    border-bottom: solid 1px ${color["gray-0"]};
  `,
  group: css`
    margin-top: 16px;
    margin-bottom: 16px;
  `,
  item: css`
    margin-top: 8x;
  `,
});

interface Props {
  navigate?: (key: string) => void;
}

const indexes = [
  {
    title: "はじめに・目次",
    path: "/",
    children: [],
  },
  {
    title: "導入",
    namespace: "/introduction",
    children: [
      {
        title: "Egg Cutterについて",
        path: "/about",
      },
      {
        title: "Egg Cutterでできること",
        path: "/features",
      },
      {
        title: "ユースケース・使用例",
        path: "/usecase",
      },
    ],
  },
  {
    title: "使い方",
    namespace: "/useage",
    children: [
      {
        title: "画面の見方",
        path: "/screen",
      },
      {
        title: "作業の流れ",
        path: "/workflow",
      },
      // {
      //   title: "各パートの操作方法",
      //   path: "/components",
      // },
    ],
  },
];

interface NodeParams {
  title: string;
  path?: string;
  namespace?: string;
  children?: NodeParams[];
}

interface NodeProps {
  node: NodeParams;
  depth?: number;
  rootPath?: string;
  titleElement?: string;
}

const Node: React.FC<NodeProps> = ({ rootPath = "/", depth = 0, node }) => {
  const _path = rootPath + (node.path || node.namespace || "");
  const title = React.createElement(
    `h${depth + 2}`,
    [],
    [
      <Link
        css={
          !node.path
            ? css`
                pointer-events: none;
              `
            : undefined
        }
        to={_path}
      >
        {depth > 0 ? (
          <Icon
            css={css`
              position: relative;
              top: 2px;
              margin-right: 16px;
            `}
            size="12px"
            icon="right"
          />
        ) : null}
        {node.title}
      </Link>,
    ]
  );

  return (
    <li key={_path} css={style.item}>
      {title}
      {node.children ? (
        <ul css={style.group}>
          {node.children.map((item: NodeParams) => {
            const key = item.path || item.namespace;
            return (
              <Node key={key} node={item} rootPath={_path} depth={depth + 1} />
            );
          })}
        </ul>
      ) : null}
    </li>
  );
};

const Sidebar: React.FC<Props> = () => {
  const nodeList = React.useMemo(() => {
    return indexes.map((item) => {
      const key = item.path || item.namespace;
      return <Node key={key} rootPath="/docs" node={item} />;
    });
  }, [indexes]);

  return (
    <div css={style.container}>
      <ul css={style.indexes}>{nodeList}</ul>
      <Footer />
    </div>
  );
};

export default Sidebar;
